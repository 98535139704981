exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manuales-productos-js": () => import("./../../../src/pages/manuales-productos.js" /* webpackChunkName: "component---src-pages-manuales-productos-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../../../src/pages/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-sorteoeuro-2024-jsx": () => import("./../../../src/pages/sorteoeuro2024.jsx" /* webpackChunkName: "component---src-pages-sorteoeuro-2024-jsx" */),
  "component---src-pages-vidaa-js": () => import("./../../../src/pages/vidaa.js" /* webpackChunkName: "component---src-pages-vidaa-js" */),
  "component---src-templates-audio-category-jsx": () => import("./../../../src/templates/audioCategory.jsx" /* webpackChunkName: "component---src-templates-audio-category-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-legal-jsx": () => import("./../../../src/templates/legal.jsx" /* webpackChunkName: "component---src-templates-legal-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-refrigerators-category-jsx": () => import("./../../../src/templates/refrigeratorsCategory.jsx" /* webpackChunkName: "component---src-templates-refrigerators-category-jsx" */),
  "component---src-templates-tv-category-jsx": () => import("./../../../src/templates/tvCategory.jsx" /* webpackChunkName: "component---src-templates-tv-category-jsx" */)
}

